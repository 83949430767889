import React from 'react';
import App from 'next/app';
import Head from 'next/head';
// import * as Sentry from '@sentry/browser';
import { NextPageContext } from 'next';
import withRedux from 'next-redux-wrapper';
import { Provider } from 'react-redux';
import store, { Store } from '../redux/store';
import { isPc } from '../lib/utils/UtilsEnvi';

// Sentry.init({
// 	dsn: 'https://33ecee5bc1b443759280d26e878ac793@sentry.qlchat.com/9',
// 	integrations: [
// 		new Sentry.Integrations.Breadcrumbs({
// 			console: false,
// 		}),
// 	],
// });

/**
 * RootApp
 */
class RootApp extends App<any> {
	private pc: boolean = isPc();

	/**
	 * init
	 *
	 * @static
	 * @param {*} { Component, ctx }
	 * @returns {Promise<any>}
	 * @memberof RootApp
	 */
	public static async getInitialProps({ Component, ctx }: any): Promise<any> {
		let pageProps: any = {};

		if (Component.getInitialProps) {
			pageProps = await Component.getInitialProps(ctx);
		}

		const { protocol, hostname, path } = ctx.req;
		// 插入系统时间
		pageProps.sysTime = Date.now();
		const urlObj: any = { protocol, hostname, path, url: `${protocol}://${hostname}${path}` };

		return { pageProps, urlObj };
	}

	/**
	 * catch
	 *
	 * @param {*} error
	 * @param {*} errorInfo
	 * @returns {*}
	 * @memberof RootApp
	 */
	public componentDidCatch(error: any, errorInfo: any): any {
		// Sentry.withScope((scope: any): any => {
		// 	Object.keys(errorInfo).forEach((key: any): any => {
		// 		scope.setExtra(key, errorInfo[key]);
		// 	});

		// 	Sentry.captureException(error);
		// });

		super.componentDidCatch(error, errorInfo);
	}

	/**
	 * hole
	 */
	public componentDidMount(): void {
		const jssStyles: any = document.querySelector('#jss-server-side');
		if (jssStyles) {
			jssStyles.parentNode!.removeChild(jssStyles);
		}

		// 百度统计
		((): any => {
			let hm: any = document.createElement('script');
			hm.src = '//hm.baidu.com/hm.js?b6d6e9556875be73889a78c415efb856';
			hm.crossorigin = 'anonymous';
			let s: any = document.getElementsByTagName('script')[0];
			s.parentNode!.insertBefore(hm, s);
		})();

		// vcdebug
		const vcdebug: any = document.getElementById('vconsole');
		const vcdebugValue: any = vcdebug.value || '';
		if ((window && window.location.search.indexOf('_vcdebug') !== -1) || vcdebugValue === 'true') {
			const script: any = document.createElement('script');

			script.src = '//res.wx.qq.com/mmbizwap/zh_CN/htmledition/js/vconsole/2.5.1/vconsole.min.js';
			script.crossorigin = 'anonymous';
			script.async = true;

			document.body.appendChild(script);
		}

		// 环境切换
		if (window && window.location.search.indexOf('_changeDev') !== -1) {
			const script: any = document.createElement('script');

			script.src = '//res.qianliaowang.com/res-components/lib/change-hxy-env.js';
			script.crossorigin = 'anonymous';
			script.async = true;

			document.body.appendChild(script);
		}
	}

	/**
	 * render
	 *
	 * @returns {*}
	 * @memberof MyApp
	 */
	public render(): any {
		const { store, Component, pageProps, urlObj } = this.props;

		return (
			<React.Fragment>
				<Provider store={store}>
					<Head>
						<title>{''}</title>
						{this.pc ? (
							<>
								<meta name="mobile-agent" content={`format=html5; url=https://www.qianliao.net${urlObj.path}`}></meta>
								<link rel="alternate" media="only screen and (max-width: 640px)" href={`https://www.qianliao.net${urlObj.path}`}></link>
							</>
						) : (
							<>
								<link rel="alternate" media="only screen and (max-width: 640px)" href={`https://www.qianliao.net${urlObj.path}`}></link>
								<script src="//res.qianliaowang.com/res-components/components_modules/lib-flexible/1.0.1/lib-flexible.js"></script>
							</>
						)}
						<meta property="og:url" content={urlObj.url} />
						<meta name="applicable-device" content={this.pc ? 'pc' : 'mobile'} />
						<meta property="og:image" content="//img.qlchat.com/qlLive/activity/image/NOA5JBN9-ONOC-KMVU-1687853805547-ECT22A41PLAF.png?x-oss-process=image/format,webp" ></meta>
						<link rel="canonical" href={urlObj.url} />
					</Head>

					<Component {...pageProps} store={store} />
				</Provider>
			</React.Fragment>
		);
	}
}

export default withRedux(store)(RootApp);
