import { INIT_COMMON, INIT_CATEGORYLIST } from '../../actions/modules/ModulesActionsCommon';

/**
 * 初始化STATE
 */
export const initialState: any = {
	categoryList: {
		first: [],
		second: [],
	},
};

/**
 * common
 */
function common(state: any = initialState, action: any): any {
	const { type, item } = action;

	switch (type) {
		case INIT_COMMON:
			return {
				...state,
				...action.pageData,
			};
		case INIT_CATEGORYLIST:
			return {
				...state,
				categoryList: action.list,
			};
		default: {
			return state;
		}
	}
}

export default common;
