import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, Store as ReduxStore } from 'redux';
import { createLogger } from 'redux-logger';
import reducers, { initialState } from '../reducer';

/**
 * 环境判断
 */
const dev: boolean = process.env.NODE_ENV !== 'production';

/**
 * dev 使用 redux-devtools-extension
 * prod 使用 redux-devtools-extension/logOnlyInProduction
 */
// tslint:disable-next-line:no-var-requires
const { composeWithDevTools } = dev ? require('redux-devtools-extension') : require('redux-devtools-extension/logOnlyInProduction');

/**
 * Store
 */
export type Store = ReduxStore<typeof initialState>;

/** export */
export default (state = initialState): Store => {
	const middlewares: any = dev ? [thunkMiddleware, createLogger()] : [];

	return createStore(reducers, state, composeWithDevTools(applyMiddleware(...middlewares)));
};
