const PREFIX: string = 'COMMON_';

export const INIT_COMMON: string = PREFIX + 'INIT';
export const INIT_CATEGORYLIST: string = PREFIX + 'INIT_CATEGORYLIST';

/**
 * 初始化
 */
export function initCommonData(pageData: any): any {
	return {
		type: INIT_COMMON,
		pageData,
	};
}

/**
 * 更新分类列表
 */
export function updateCategoryList(l: any): any {
	const list: any = {
		first: [],
		second: {},
	};

	l.forEach((item: any): void => {
		if (item.parentId === '0' || !item.parentId) {
			// 一级目录
			list.first.push(item);
		} else {
			// 二级目录
			if (!list.second[item.parentId]) list.second[item.parentId] = [];
			list.second[item.parentId].push(item);
		}
	});

	return {
		type: INIT_CATEGORYLIST,
		list,
	};
}
