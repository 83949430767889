import { combineReducers } from 'redux';
import common, { initialState as commonState } from './modules/ModulesReducerCommon';

/**
 * 注册state
 */
export const initialState: any = {
	common: commonState,
};

/**
 * 合并
 */
export default combineReducers({
	common,
});
